.failedConnectionContainer {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	opacity: 0.95;
	background-color: #1e1f22;
	position: absolute;
	z-index: 5;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	color: white;
	h1 {
		font-family: Industry;
		font-size: 35px;
	}
	.warningIconWrapper {
		width: 170px;
		height: 160px;
		margin-bottom: 45px;
	}
	.backAgainButtonsContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 150px;
		height: 90px;
		.circleTextContainer {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			height: 90px;
			font-size: 15px;
			.whiteCircle {
				background-color: #ffffff !important;
			}
			.whiteCircle:hover {
				background-color: #ffffff !important;
			}
			.blackCircle {
				width: 50px;
				height: 50px;
				border-radius: 50px;
				background-color: #ffffff;
				cursor: pointer;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 12px;
			}
			.greenCircle {
				background-color: #56ae4d !important;
			}
			.greenCircle:hover {
				background-color: #377831 !important;
			}
			.iconWrapper {
				width: 30px;
				height: 30px;
			}
			.rotateNinety {
				transform: rotate(-90deg);
			}
		}
	}
	.warningText {
		font-size: 15px;
	}
	.textChecboxContainer {
		display: flex;
		font-size: 15px;
		.checkboxContainer {
			width: 20px;
			height: 20px;
			.ionCheckbox {
				background: #56ae4d;
			}
		}
	}
}
