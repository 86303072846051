.tooltip-container {
	position: relative;
}

.tooltip-box {
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 8px;
	border-radius: 5px;
	top: 5px;
	left: 60px;
	display: none;
}

.tooltip-box.visible {
	display: block;
	display: flex;
	justify-content: left;
	align-items: center;
}

.tooltip-arrow {
	position: absolute;
	bottom: 12px;
	left: -10px;
	transform: rotate(-90deg);
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
}
