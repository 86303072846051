.navViewContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	position: absolute;
	bottom: 8px;
	left: 8px;
	right: 8px;
	gap: 16px;
	background-color: #444444;
	border-radius: 8px;
}

.navViewContainerDriving {
	@extend .navViewContainer;
	background-color: transparent;

	.navigation-video {
		border-radius: 8px;
		background-color: inherit;
	}
}
