.dynamic-media {
	width: 100%;
	position: relative !important;
	background-color: #444444;
	border-radius: inherit;
}

.dynamic-media .resize-container-inner {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.dynamic-media .media-cropping-box {
	height: 100%;
	position: relative;
	overflow: hidden;
}

.dynamic-media video {
	pointer-events: none;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	box-sizing: content-box;
	background-color: black;
}

.dynamic-media-full-screen {
	padding-inline: 8px;
}
