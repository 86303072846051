.remoteVideo {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: 8px;
	border-radius: 8px;
}

.remoteVideoFullScreen {
	@extend .remoteVideo;
	margin: 0;
	bottom: 0;
	border-radius: 0;
	.dynamic-media {
		max-height: unset;
		max-width: unset;
	}
}

.sessionStartContainer {
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	opacity: 0.9;
	position: absolute;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	color: white;
	font-size: 15px;
	gap: 18px;
}

.sessionStartLoading {
	border: 8px solid transparent;
	border-top: 8px solid white;
	border-bottom: 8px solid white;
	border-right: 8px solid white;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	animation: spin 2s linear infinite;
}

.greyVideo {
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.fullScreenToggleButton {
	width: 50px;
	height: 50px;
	border-radius: 50px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
	cursor: pointer;
	position: absolute;
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 26px;
	color: rgba(0, 0, 0, 0.15);
	margin: 8px;
	background-color: #ffffff;
	opacity: 0.5;
	img {
		color: black;
		opacity: 0.5;
		font-size: 25px;
	}
}
.fullScreenToggleButton:hover {
	opacity: 0.8;
	img {
		opacity: 0.8;
	}
}
