.sessionOptionsContainer {
	display: flex;
	flex-direction: column;
	margin-block: 8px;
	width: 100%;
	gap: 5px;
	.roundedButton {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		background-color: #1e1f22;
		opacity: 0.9;
		z-index: 4;
		cursor: pointer;
		&.red {
			background-color: #d92e2e !important;
		}
		&.gray {
			background: #aaaaaa;
		}
		&.disabled {
			width: 50px !important;
			opacity: 0.5;
		}
		&.rectangle {
			.rectangleLocalVoice {
				width: 0px;
				overflow: hidden;
			}
			.localVoiceText {
				overflow: hidden;
				display: none;
			}
		}
		&.rectangle:hover {
			width: 225px;
			opacity: 1 !important;
			border-radius: 50px;
			display: flex;
			justify-content: space-between;
			padding: 0 20px 0 12px;
			&.navSpeedRectangle {
				width: 250px;
			}
			.rectangleLocalVoice {
				width: 150px;
				height: 50px;
				opacity: 1 !important;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: default;
			}
			.localVoiceText {
				font-family: Roboto;
				font-size: 15px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.33;
				letter-spacing: normal;
				color: #56ae4d;
				display: block;
			}
		}
	}
	.roundedButton:hover {
		opacity: 0.9;
	}

	.iconWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 26px;
		height: 26px;
	}
	.remoteVoiceWrapper {
		position: absolute;
		left: 120px;
	}
	.badge {
		position: absolute;
		top: 0;
		width: 20px;
		height: 20px;
		transform: translate(60%, 0);
	}
}

.speedControllerContainer {
	display: flex;
	align-items: center;
	color: #56ae4d;
	font-size: 12px;
	margin-right: 10px;
}

.indicatorContainer {
	.indicatorsWrapper {
		display: none;
		width: 150px;
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		align-items: center;
		padding-left: 13px;
		.greyIndicator {
			width: 4px;
			height: 3px;
			border-radius: 2px;
			background-color: #8e8c89;
		}
		.yellowIndicator {
			background-color: #df760d !important;
		}
		.redIndicator {
			background-color: red !important;
		}
		.greenIndicator {
			background-color: #56ae4d !important;
		}
	}
}
