.Session {
	height: 100%;
	outline: none;
}

// disable selection inside the session to make the usage of joystick easier
img,
div,
span,
p {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

.sessionContainer {
	display: flex;
	flex-direction: column;
}

.sessionInfoContainer {
	* {
		z-index: 1;
	}
	.sessionIdContainer {
		position: absolute;
		top: 16px;
		right: 16px;
	}
	.drivingImpairmentCircle {
		top: calc(100vh - 175px);
		left: 16px;
		position: absolute;
	}
	.container {
		position: absolute;
		bottom: 60px;
		left: 16px;
	}
	.robotNameContainer {
		position: absolute;
		bottom: 16px;
		left: 16px;
	}
}
