.media-devices-error-overlay {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: #1e1f2295;
	position: absolute;
	z-index: 5;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	color: white;
	font-family: Industry;
}

.media-devices-error-overlay .split-pane {
	width: 100%;
	padding: 0px 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	justify-items: center;
	align-items: center;
}
.media-devices-error-overlay .split-pane > div {
	flex-grow: 1;
	width: 50%;
	padding: 0px 12px 0px 12px;
}

.media-devices-error-overlay .split-pane .left-pane {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.media-devices-error-overlay .split-pane .left-pane > .left-pane-inner {
	max-width: 600px;
	width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.media-devices-error-overlay .split-pane .right-pane {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	position: relative;
}

.media-devices-error-overlay img.error-icon {
	width: 40px;
	height: 40px;
	object-fit: contain;
	object-position: 50% 50%;
	background-color: transparent;
}

.media-devices-error-overlay div.error-message {
	width: 100%;
	text-align: center;
	word-wrap: break-word;
	flex-direction: row;
	font-size: 24px;
	margin: 45px 0px;
}

.media-devices-error-overlay img.media-devices-permissions-settings {
	width: 80%;
	object-fit: contain;
	border-radius: 4px;
}

.media-devices-error-overlay .action-buttons-container {
	width: 100%;
	margin-top: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.media-devices-error-overlay .action-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100px;
	padding: 8px 24px;
	border-radius: 6px;
	cursor: pointer;
	margin: 0px 12px;
}

.media-devices-error-overlay .reload-page-button {
	background-color: whitesmoke;
	color: black;
}

.media-devices-error-overlay .end-session-button {
	background-color: #d92e2e;
	color: white;
}

.media-devices-error-overlay .time-remaining {
	font-size: 16px;
	padding: 20px 0px;
}
