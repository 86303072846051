.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 11px;
	border-radius: 5px;
	background-color: #1e1f22e6;
	&.isMinimized {
		//width: 20px;
		//height: 50px;
		border-radius: 50px;
		opacity: 0.5;
		padding: 11px;
	}
	&.isMinimized:hover {
		opacity: 0.95;
	}
	p {
		font-size: 14px;
		line-height: 20px;
		color: #ffffff;
	}
	.indicator-label {
		margin: 0;
		margin-left: 10px;
	}
	.navIcon {
		height: 24px;
		width: 24px;
		color: #56ae4d;
	}
	.docking-wrapper {
		display: flex;
		flex-direction: row;
		.chip {
			background-color: #666666;
			padding: 2px 12px;
			border-radius: 12px;
			margin-left: 4px;
			.chip-label {
				font-size: 11px;
				line-height: 17px;
				margin: 0;
			}
		}
	}
	.closeIconWrapper {
		margin-left: 18px;
		padding-right: 4px;
	}
}
