.sessionEndPauseContainer {
	width: 100%;
	height: 100vh;
	background-color: rgba(30, 31, 34, 0.75);
	position: absolute;
	z-index: 6;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	color: white;
	top: 0;
	left: 0;
	h1 {
		font-family: Industry;
		font-size: 35px;
		color: white;
	}
	.endPauseButtonsContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 150px;
		height: 160px;
		.circleTextContainer {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			height: 90px;
			font-size: 15px;
			.redCircle {
				background-color: #d92e2e !important;
			}
			.redCircle:hover {
				background-color: #9e0e0e !important;
			}
			.blackCircle {
				width: 50px;
				height: 50px;
				border-radius: 50px;
				background-color: #1e1f22;
				cursor: pointer;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 12px;
			}
			.greenCircle {
				background-color: #56ae4d !important;
			}
			.whiteCircle {
				background-color: white !important;
			}
			.greenCircle:hover {
				background-color: #377831 !important;
			}
			.iconWrapper {
				width: 30px;
				height: 30px;
				fill: #ffffff !important;
			}
		}
	}
	.warningText {
		margin-bottom: 60px;
		font-size: 15px;
	}
	.cancelButtonContainer {
		width: 185px;
		height: 50px;
		border-radius: 30px;
		border: solid 1px #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: Roboto;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
	}
	.cancelButtonContainer:hover {
		background-color: white;
		color: #1e1f22;
	}
}

.sessionEndPauseOpacity {
	opacity: 0.95 !important;
}
